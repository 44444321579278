export const GET_CHANNELS='GET_CHANNELS'
export const CREATE_CHANNEL='CREATE_CHANNEL'
export const EDIT_CHANNEL = 'EDIT_CHANNEL'
export const DELETE_CHANNEL = 'DELETE_CHANNEL'
export const SELECTED_CHANNELS='SELECTED_CHANNELS'
export const GET_ENGINES='GET_ENGINES'

export const GET_TYPE_ACTION_CHANNEL="GET_TYPE_ACTION_CHANNEL"
export const UPDATE_TYPE_ACTION_CHANNEL="UPDATE_TYPE_ACTION_CHANNEL"
export const CURRENT_CHANNEL="CURRENT_CHANNEL"
export const UPDATE_CHANNEL="UPDATE_CHANNEL"