import EmailIcon from "@mui/icons-material/Email";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { AppContext } from "App";
import { ReactComponent as CardIcon } from "assets/subscripe/card.svg";
import { AppConfig } from "enums/config";
import { useContext, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getWallet } from "redux/subscribe/actions";
import { subscribeSelector } from "redux/subscribe/selector";
import { SubscribeIntialState } from "redux/subscribe/type";
import { currencyFormatter } from "utils/helper";

type Props = {};

const WalletBalance = (props: Props) => {
  const [, theme, minPcMatch] = useContext(AppContext);
  const dispatch = useDispatch();
  const { walletBalance, balanceType }: SubscribeIntialState =
    useSelector(subscribeSelector);

  useEffect(() => {
    dispatch(getWallet());
  }, [dispatch]);
  const supportIconDisplay = useMemo(
    () =>
      balanceType === "message" ? (
        <EmailIcon
          sx={{
            width: "1.2rem",
            height: "1.2rem",
          }}
        />
      ) : (
        <CardIcon
          width={"1.2rem"}
          height={"1.2rem"}
          fill={
            minPcMatch
              ? theme.palette.general.offWhite
              : theme.palette.general.darkPrimary
          }
        />
      ),
    [
      balanceType,
      minPcMatch,
      theme.palette.general.offWhite,
      theme.palette.general.darkPrimary,
    ]
  );
  return (
    <Grid
      container
      justifyContent="space-around"
      sx={{
        background: minPcMatch
          ? theme.palette.light
          : theme.palette.general.white,
        borderRadius: AppConfig.BORDER_RADIUS_MIN,
        p: "0.2567em .3em",
        border: minPcMatch
          ? "none"
          : `1px solid ${theme.palette.general.grayDark}`,
      }}
    >
      <Grid
        item
        container
        xs={12}
        lg={4}
        p={minPcMatch ? ".5em" : "0 .6em"}
        justifyContent={"space-around"}
      >
        <Button
          variant="text"
          endIcon={!minPcMatch ? supportIconDisplay : ""}
          startIcon={minPcMatch ? supportIconDisplay : ""}
          sx={{
            p: 0,
            textTransform: "none",
            fontSize: minPcMatch ? "1rem" : ".85rem",
            "&:hover": { bgcolor: "transparent" },
            color: theme.palette.general.successDarker,
            fontWeight: 500,
            justifyContent: minPcMatch ? "flex-start" : "auto",
          }}
          disableRipple
          disableElevation
          fullWidth={minPcMatch}
        >
          {balanceType === "message"
            ? walletBalance
            : currencyFormatter(walletBalance, "SAR")}
        </Button>
      </Grid>
    </Grid>
  );
};

export default WalletBalance;
