import { uuid4 } from "@sentry/utils";
import { AxiosResponse } from "axios";
import { AppConfig } from "enums/config";
import { OperationStatus } from "enums/operationStatus";
import { SessionEngine } from "enums/sessionEngine";
import { SessionType } from "enums/sessions";
import { loading, stopLoading, triggerAlert } from "redux/appSetting/actions";
import store from "redux/store";
import { socket } from "socketConfig/socketConnect";
import { errorHandling } from "utils/errorHandling";
import localize from "utils/localize";
import chatService from "../../services/chat.service";
import {
  acknowledgeMessage,
  activityAck,
  callParticipants,
  closeAgentSession,
  escaleteAgentSession,
  getCallParticipants,
  getEsclatedSession,
  getSessionMessage,
  getSuperSession,
  joinStatus,
  leaveStatus,
  listAgentSessions,
  listClientSessions,
  listMsgActivities,
  loadAgentSessions,
  loadClientSessions,
  loadEscalatedSession,
  loadMessage,
  loadSuperSession,
  messageLimitStatus,
  messageOutStatus,
  messageStatus,
  msgActivities,
  recieveMessage,
  returnAgentSession,
  returnToAgent,
  sendMessage,
  sessionClosedSuccess,
  sessionOpenSuccess,
  sessionTaken,
  takeAgentSession,
  takeEsclatedSession,
  watchSession,
  watchSessionSuccess,
} from "../../socketConfig/socketEvent";
import {
  AttachmentList,
  directMessageLayout,
  twitterMessageLayout,
} from "../../utils/dataLayout/messageLayout";
import { commonMsgType } from "./messageType";
import { currentFilters } from "./type";
import {
  ACTIVE_INACTIVE_SESSION_OFFSET,
  ACTIVE_SESSION_OFFSET,
  ADD_ITEM_LIST_AGENT_SESSION,
  AGENT_TAKE_SESSION_SUCCESS,
  APPLY_FILTERS,
  ASSIGN_ESCALATED_SESSION_OFFSET,
  CLEAR_SELECTED_SESSION,
  CLIENT_SESSION_OFFSET,
  CLOSE_SESSION,
  CLOSE_SESSION_OFFSET,
  DELETE_DRAFT_MESSAGE,
  END_VIDEO_CALL,
  ESCALATED_SESSION_OFFSET,
  ESCALATE_SESSION,
  GET_CLIENT_INFO,
  GET_SESSION_MSG,
  INACTIVE_SESSION_OFFSET,
  LEAVE_SESSION_SUCCESS,
  LIST_AGENT_SESSION,
  LIST_CLIENT_SESSION,
  LIST_ESCALATED_SEESION,
  LOADING_REACTION_LIST,
  LOAD_AGENT_SEESION,
  LOAD_CLIENT_SESSION,
  LOAD_ESCALATED_SEESION,
  LOAD_SESSION_MSG,
  MESSAGE_LIMIT_STATUS,
  MESSAGE_STATUS,
  MOVE_ITEM_ARCHIVE_TO_LIST_SESSION,
  RECEIVE_NEW_MESSAGE,
  RECEIVE_NEW_REACTION,
  RECEIVE_REACTION_LIST,
  RESET_SELECT_SESSION,
  RETURN_SESSION,
  RETURN_TO_AGENT,
  SAVE_DRAFT_MESSAGE,
  SELECT_SESSION,
  SEND_ACKNOWLEDGE,
  SEND_MSG,
  SEND_MSG_API,
  SEND_UPDATE_PENDING,
  SESSION_AGENT_FILTER,
  SESSION_CLOSED_SUCCESS,
  SESSION_GROUP_FILTER,
  SESSION_MSG_OFFSET,
  SESSION_OPEN_SUCCESS,
  SET_CLIENT_INFO,
  SET_DONE_SESSION,
  START_VIDEO_CALL,
  TAKE_ESCALATED_SESSION,
  TAKE_SESSION,
  TAKE_SESSION_SUCCESS,
  TRANSFER_GROUP,
  UPDATE_CURRENT_SESSION_TYPE,
  UPDATE_MULTI_SELECTED_SESSIONS,
  UPDATE_VALUE_ENABLE_SEARCH,
  VIDEO_CALL_PARTICIPANTS,
  WATCH_SESSION,
  WATCH_SESSION_SUCCESS,
} from "./types";
import { setPredefine } from "./utils";

// for agent
export const loadSession =
  (
    sessionStatus: "active" | "inactive" | "close",
    offset?: number,
    selectedTab?: string,
    filters?: object
  ) =>
  async (dispatch: any) => {
    try {
      await loadAgentSessions(sessionStatus, offset, selectedTab, filters);
      return dispatch({
        type: LOAD_AGENT_SEESION,
        payload: sessionStatus,
      });
    } catch (error) {
      console.log({ loadSessionsError: error });
    }
  };

// for admin
export const updateEnableSearch =
  (isEnableSearch: boolean) => (dispatch: any) => {
    return dispatch({
      type: UPDATE_VALUE_ENABLE_SEARCH,
      payload: isEnableSearch,
    });
  };

export const superSessionLoad =
  (
    sessionStatus: "active" | "inactive" | "close",
    offset?: number,
    selectedTab?: string,
    filters?: object
  ) =>
  async (dispatch: any) => {
    try {
      await loadSuperSession(sessionStatus, offset, selectedTab, filters);
      return dispatch({
        type: LOAD_AGENT_SEESION,
        payload: sessionStatus,
      });
    } catch (error) {
      console.log({ loadSuperSessionsError: error });
    }
  };

export const superEscalatedSessionLoad =
  (
    sessionStatus: "active" | "inactive",
    offset?: number,
    selectedTab?: string,
    filters?: object
  ) =>
  async (dispatch: any) => {
    try {
      await loadEscalatedSession(sessionStatus, offset, selectedTab, filters);
      return dispatch({
        type: LOAD_ESCALATED_SEESION,
        payload: sessionStatus,
      });
    } catch (error) {
      console.log({ loadSuperEscalatedSessionsError: error });
    }
  };

//? still don't know how it will affect
export const sessionWatch = (sessionKey: string) => async (dispatch: any) => {
  try {
    await watchSession(sessionKey);
    return dispatch({
      type: WATCH_SESSION,
    });
  } catch (error) {
    console.log({ watchSessionError: error });
  }
};

// export const loginStatusCall = () => async (dispatch: any) => {
//     try {
//       let count = 1;
//       await loginStatus((data: {status:'SUCCESS'}) => {
//         if (count === 1) {
//           dispatch({
//             type: LOGIN_STATUS,
//             payload: data.status
//             ,
//           });
//           count++;
//           return;
//         }
//       });
//     } catch (error) {
//       console.log({ loginStatusError: error });
//     }
//   };

//? still don't know how it will affect
export const sessionWatchSuccess = () => async (dispatch: any) => {
  try {
    let count = 1;
    await watchSessionSuccess((data: object[]) => {
      if (count === 1) {
        dispatch({
          type: WATCH_SESSION_SUCCESS,
          payload: data,
        });
        count++;
        return;
      }
    });
  } catch (error) {
    console.log({ watchSessionSuccessError: error });
  }
};

export const listEscalated = () => async (dispatch: any) => {
  try {
    //let count = 1;
    await getEsclatedSession((data: object[]) => {
      //if (count === 1) {
      dispatch({
        type: LIST_ESCALATED_SEESION,
        payload: data,
      });
      //count++;
      // return;
      //}
    });
  } catch (error) {
    console.log({ listEsclatedSessionError: error });
  }
};

export const listSuperSession = () => async (dispatch: any) => {
  try {
    // let count = 1;
    await getSuperSession((data: object[]) => {
      //   if (count === 1) {
      dispatch({
        type: LIST_AGENT_SESSION,
        payload: data,
      });
      // count++;
      // return;
      //   }
    });
  } catch (error) {
    console.log({ listSuperSessionError: error });
  }
};

//todo create a button for that
export const returnSessionToAgent =
  (sessionKey: string) => async (dispatch: any) => {
    try {
      await returnToAgent(sessionKey);
      return dispatch({
        type: RETURN_TO_AGENT,
      });
    } catch (error) {
      console.log({ returnSessionToAgentError: error });
    }
  };

export const escalatedSessionTake =
  (sessionKey: string) => async (dispatch: any) => {
    try {
      await takeEsclatedSession(sessionKey);
      return dispatch({
        type: TAKE_ESCALATED_SESSION,
      });
    } catch (error) {
      console.log({ escalatedSessionTake: error });
    }
  };

export const startVideoCall = () => async (dispatch: any) => {
  try {
    // this must also used to send event join call
    dispatch({
      type: START_VIDEO_CALL,
    });
  } catch (error) {
    console.log({ startVideoCallError: error });
  }
};

export const endVideoCall = () => async (dispatch: any) => {
  try {
    // this must also used to send event join call
    dispatch({
      type: END_VIDEO_CALL,
    });
  } catch (error) {
    console.log({ startVideoCallError: error });
  }
};

// for agent
export const listSessions = () => async (dispatch: any) => {
  try {
    // let count = 1;
    await listAgentSessions((data: object[]) => {
      //   if (count === 1) {
      // console.log({count,data})
      dispatch({
        type: LIST_AGENT_SESSION,
        payload: data,
      });
      // count++;
      // return;
      //   }
    });
  } catch (error) {
    console.log({ listSessionsError: error });
  }
};

export const loadClientSession =
  (
    sessionStatus: "all",
    offset?: number,
    filters: {
      username: string;
      dateSent: "DESC" | "ASC";
    } = {
      username: "",
      dateSent: "DESC",
    }
  ) =>
  async (dispatch: any) => {
    try {
      await loadClientSessions(sessionStatus, offset, filters);
      return dispatch({
        type: LOAD_CLIENT_SESSION,
        payload: sessionStatus,
      });
      console.log({ loadSessionsError: sessionStatus });
    } catch (error) {
      console.log({ loadSessionsError: error });
    }
  };

export const listAllClientSessions = () => async (dispatch: any) => {
  try {
    await listClientSessions((data: object[]) => {
      dispatch({
        type: LIST_CLIENT_SESSION,
        payload: data,
      });
    });
  } catch (error) {
    console.log({ listSessionsError: error });
  }
};

export const newSessionComposeMsg = () => async (dispatch: any) => {
  try {
    let count = 1;
    messageOutStatus((data: any) => {
      if (count === 1) {
        let status: boolean = data?.status === OperationStatus.SUCCESS;
        if (!status) {
          if (
            data?.sessionType === SessionEngine.whatsapp &&
            data?.sessionStatus === SessionType.CLOSED
          ) {
            status = false;
          } else {
            status =
              data?.sessionStatus === SessionType.ASSIGNED ||
              data?.sessionStatus === SessionType.CLOSED;
          }
        }
        if (status) {
          dispatch(triggerAlert(localize("create_message_success"), "success"));
          dispatch(sessionOpen());
          dispatch({
            type: ADD_ITEM_LIST_AGENT_SESSION,
            payload: data,
          });
          count++;
          return;
        } else {
          dispatch(
            triggerAlert(
              localize(data?.failMsg || "create_message_failed"),
              "error"
            )
          );
        }
      }
    });
  } catch (error) {
    console.log({ listSessionsError: error });
  }
};

// export const newSessionComposeMsg = () => async (dispatch: any) => {
//   try {
//     let count = 1;
//     messageOutStatus((data: any) => {
//       if (count === 1) {
//         if (data?.status == "SUCCESS") {
//           dispatch(triggerAlert(localize("create_message_success"), "success"));
//           dispatch(sessionOpen());
//           dispatch({
//             type: ADD_ITEM_LIST_AGENT_SESSION,
//             payload: data,
//           });
//           count++;
//           return;
//         } else {
//           dispatch(triggerAlert(localize(data?.failMsg || "create_message_failed"), "error"));
//         }
//       }
//     });
//   } catch (error) {
//     console.log({ listSessionsError: error });
//   }
// };

export const returnSession =
  (session: {
    sessionKey: string;
    sessionStatus: SessionType.ESCALATED | SessionType.UNASSIGNED;
  }) =>
  async (dispatch: any) => {
    try {
      await returnAgentSession(session);
      return dispatch({
        type: RETURN_SESSION,
      });
    } catch (error) {
      console.log({ returnSessionError: error });
    }
  };

export const closeSession =
  (sessionKey: string, withSurvey: boolean) => async (dispatch: any) => {
    try {
      await closeAgentSession(sessionKey, withSurvey);
      return dispatch({
        type: CLOSE_SESSION,
      });
    } catch (error) {
      console.log({ closeSessionError: error });
    }
  };

export const takeSession = (sessionKey: string) => async (dispatch: any) => {
  try {
    await takeAgentSession(sessionKey);
    return dispatch({
      type: TAKE_SESSION,
    });
  } catch (error) {
    console.log({ takeSessionError: error });
  }
};

// socketCall and it dispatch action
export const joinSessionStatus = () => async (dispatch: any) => {
  try {
    let count = 1;
    joinStatus((data: any) => {
      if (count === 1) {
        dispatch({
          type: TAKE_SESSION_SUCCESS,
          payload: data,
        });
        if (data?.status === "SUCCESS") {
          dispatch(
            getSessionMsg(data?.sessionKey, AppConfig.GET_MESSAGE_PATCH_SIZE)
          );
        }
        if (data?.status === "FAILED") {
          dispatch(
            triggerAlert(
              localize(data?.message || "take_session_failed"),
              "error"
            )
          );
        }
        count++;
        return;
      }
    });
  } catch (error) {
    console.log({ joinSessionStatusError: error });
  }
};

export const sessionOpen = () => async (dispatch: any) => {
  try {
    const { auth } = store.getState();
    const profile: any = auth.profile;
    sessionOpenSuccess((data: object[]) => {
      //   if (count === 1) {
      // console.log({ sessionOpenData: data });
      dispatch({
        type: SESSION_OPEN_SUCCESS,
        payload: { ...data, currentUser: profile },
      });
      // count++;
      return;
      //   }
    });
  } catch (error) {
    console.log({ sessionOpenError: error });
  }
};

export const sessionClosed = () => async (dispatch: any) => {
  try {
    // let count = 1;
    const { auth } = store.getState();
    const profile: any = auth.profile;
    sessionClosedSuccess((data: object[]) => {
      //   if (count === 1) {
      dispatch({
        type: SESSION_CLOSED_SUCCESS,
        payload: { ...data, currentUser: profile },
      });
      // count++/;
      return;
      //   }
    });
  } catch (error) {
    console.log({ sessionCloseError: error });
  }
};

export const leaveSessionStatus = () => async (dispatch: any) => {
  try {
    let count = 1;
    leaveStatus((data: { status: string; message: string }) => {
      if (count === 1) {
        const { auth } = store.getState();
        const profile = auth.profile;
        if (data?.status !== "FAILED") {
          dispatch({
            type: LEAVE_SESSION_SUCCESS,
            payload: { ...data, currentUserRole: profile.role },
          });
          count++;
          return;
        }
        if (data?.status === "FAILED") {
          dispatch(triggerAlert(data?.message, "error"));
        }
      }
    });
  } catch (error) {
    console.log({ leaveSessionStatusError: error });
  }
};

export const takenSession = () => async (dispatch: any) => {
  try {
    //let count = 1;
    sessionTaken((data: object[]) => {
      // console.log(count);
      //if (count === 1) {
      dispatch({
        type: AGENT_TAKE_SESSION_SUCCESS,
        payload: data,
      });
      //count++;
      //return;
      //}
    });
  } catch (error) {
    console.log({ sessionTakenError: error });
  }
};

export const escaleteSession =
  (sessionKey: string) => async (dispatch: any) => {
    try {
      await escaleteAgentSession(sessionKey);
      return dispatch({
        type: ESCALATE_SESSION,
      });
    } catch (error) {
      console.log({ escaleteSessionError: error });
    }
  };

export const closeSessionOffset = (offset: number) => (dipsatch: any) => {
  return dipsatch({
    type: CLOSE_SESSION_OFFSET,
    payload: offset,
  });
};

export const activeInactiveSessionOffset =
  (offset: number) => (dipsatch: any) => {
    return dipsatch({
      type: ACTIVE_INACTIVE_SESSION_OFFSET,
      payload: offset,
    });
  };
export const activeSessionOffset = (offset: number) => (dipsatch: any) => {
  return dipsatch({
    type: ACTIVE_SESSION_OFFSET,
    payload: offset,
  });
};
export const inactiveSessionOffset = (offset: number) => (dipsatch: any) => {
  return dipsatch({
    type: INACTIVE_SESSION_OFFSET,
    payload: offset,
  });
};
export const assignEscalatedSessionOffset =
  (offset: number) => (dipsatch: any) => {
    return dipsatch({
      type: ASSIGN_ESCALATED_SESSION_OFFSET,
      payload: offset,
    });
  };
export const escalatedSessionOffset = (offset: number) => (dipsatch: any) => {
  return dipsatch({
    type: ESCALATED_SESSION_OFFSET,
    payload: offset,
  });
};

export const clientSessionOffset = (offset: number) => (dipsatch: any) => {
  return dipsatch({
    type: CLIENT_SESSION_OFFSET,
    payload: offset,
  });
};

export const selectSession = (sessionId: string) => async (dispatch: any) => {
  try {
    //await messageLimit({ data: "data" });
    return dispatch({
      type: SELECT_SESSION,
      payload: sessionId,
    });
  } catch (error) {
    console.log({ selectSessionError: error });
  }
};

export const resetSelectedSession = () => async (dispatch: any) => {
  try {
    return dispatch({
      type: RESET_SELECT_SESSION,
    });
  } catch (error) {
    console.log({ resetSelectedSessionError: error });
  }
};

export const msgLimitStatus = () => async (dispatch: any) => {
  try {
    let count = 1;
    messageLimitStatus((data: object[]) => {
      if (count === 1) {
        dispatch({
          type: MESSAGE_LIMIT_STATUS,
          payload: data,
        });
        count++;
        return;
      }
    });
  } catch (error) {
    console.log({ msgLimitStatusError: error });
  }
};

export const getSessionMsg =
  (sessionKey: string, limit: number = 0, offset: number = 0) =>
  async (dispatch: any) => {
    try {
      let count = 1;
      if (count === 1) {
        await getSessionMessage(sessionKey, limit, offset);
        dispatch({
          type: SESSION_MSG_OFFSET,
          payload: offset,
        });
        count++;
        return dispatch({
          type: GET_SESSION_MSG,
        });
      }
    } catch (error) {
      console.log({ getSessionMsgError: error });
    }
  };

export const loadSessionMsg = () => (dispatch: any) => {
  try {
    loadMessage((data: any) => {
      let messages = __acknoledgeMessage(data);
      dispatch({
        type: LOAD_SESSION_MSG,
        payload: messages,
      });
      // send acknoledge if there are unread msg
      // console.log({ LOAD_SESSION_MSG: messages });
    });
  } catch (error) {
    console.log({ loadSessionMsgError: error });
  }
};

export const recieveSessionMsg = () => (dispatch: any) => {
  try {
    //let count = 1;
    recieveMessage((data: any) => {
      // if (count === 1) {
      dispatch({
        type: RECEIVE_NEW_MESSAGE,
        payload: data,
      });
      //count++;
      return;
      // }
    });
  } catch (error) {
    console.log({ recieveSessionMsgError: error });
  }
};

export const clearSelectedSession = () => (dispatch: any) => {
  return dispatch({
    type: CLEAR_SELECTED_SESSION,
  });
};

export const sendAknowledgeMsg =
  (sessionKey: string, messageKey: string[]) => (dispatch: any) => {
    acknowledgeMessage({
      sessionKey: sessionKey,
      messageStatus: "READ",
      messageKeys: messageKey,
    });

    return dispatch({
      type: SEND_ACKNOWLEDGE,
      payload: sessionKey,
    });
  };

export const sendNewMsg = (data: object) => async (dispatch: any) => {
  try {
    // console.log(1, { data });
    await sendMessage(data);
    return dispatch({
      type: SEND_MSG,
    });
  } catch (error) {
    console.log({ sendMsgError: error });
  }
};

export const msgStatus = () => (dispatch: any) => {
  try {
    messageStatus((data: any) => {
      dispatch({
        type: MESSAGE_STATUS,
        payload: data,
      });
    });
  } catch (error) {
    console.log({ msgStatusError: error });
  }
};

const __acknoledgeMessage = (data: any) => {
  let unreadMsg: string[] = [];
  let messagesData = data;
  // this case is a direct or whatsapp message or instagram msg or twitter direct msg
  if (data.messages) {
    unreadMsg = data.messages.map(
      (msg: any) => msg.messageStatus === "UNREAD" && msg.messageKey
    );
    unreadMsg = unreadMsg.filter((msg: any) => typeof msg === "string");
  }

  // This case if it was a twitter tweet or reply or mention or qoute
  //todo hand linkedin and twitter
  if (commonMsgType.includes(data.messageType)) {
    messagesData = __handleTwitterRecursive(data);
    unreadMsg = messagesData.messages.map(
      (msg: any) => msg.messageStatus === "UNREAD" && msg.messageKey
    );

    //handle first message
    if (messagesData.messageStatus === "UNREAD" && messagesData.messageKey) {
      unreadMsg.push(messagesData.messageKey);
    }

    unreadMsg = unreadMsg.filter((msg: any) => typeof msg === "string");
  }
  if (!store.getState().sessions.sessionsClosed[data.sessionKey]) {
    if (data.messageKey && !data.replies && !data.messages) {
      acknowledgeMessage({
        sessionKey: data.sessionKey,
        messageStatus: "READ",
        messageKeys: [data.messageKey],
      });
    }

    if (unreadMsg.length > 0) {
      acknowledgeMessage({
        sessionKey: data.sessionKey,
        messageStatus: "READ",
        messageKeys: unreadMsg,
      });
    }
  }
  return messagesData;
};

// flat the twitter messages
const __handleTwitterRecursive = (data: any) => {
  const messages: object[] = [];
  // keep searching for replies
  function recursiveReply(reply: any) {
    for (let i = 0; i < reply.length; i++) {
      // if VendorChildIDs exist then there are replies for that
      if (reply[i].VendorChildIDs) {
        reply[i].track = true;
        reply[i].main = false;
      }

      messages.push(reply[i]);
      if (reply[i].replies) {
        recursiveReply(reply[i].replies);
      }
    }
  }
  if (data.replies) {
    recursiveReply(data.replies);
  }

  /* messages.push(data);
  // console.log({ testData: messages }); */
  data.messages = messages;
  // console.log({ data });
  return data;
};

// REST actions
export const sendMessageRest =
  (formData: FormData) => async (dispatch: any) => {
    try {
      formData = setPredefine(formData);
      const res = chatService.sendMsgApi(formData);
      return dispatch({
        type: SEND_MSG_API,
        payload: res,
      });
    } catch (error) {
      console.log({ sendMessageRestError: error });
    }
  };

export const updateMsgPending =
  (data: any, file: AttachmentList | null | any) => (dispatch: any) => {
    let messageData;
    // update message to a specific format
    // console.log("testing twitter 2", { data, file });
    // if it was twitter message
    if (commonMsgType.includes(data.messageType)) {
      messageData = twitterMessageLayout(data, file);
    } else {
      // if it was direct message
      messageData = directMessageLayout(data, file);
    }
    // console.log({ messageData });
    return dispatch({
      type: SEND_UPDATE_PENDING,
      payload: messageData,
    });
  };

export const getClientInfo = (clientId: number) => async (dispatch: any) => {
  try {
    const response: AxiosResponse = await chatService.getClientInfo(clientId);
    const clientInfo = { ...response.data };
    if (response.data) {
      dispatch({
        type: GET_CLIENT_INFO,
        payload: clientInfo,
      });
    }
  } catch (er: any) {
    errorHandling(er, dispatch, "Error_getting_client_info");
  }
};

export const saveDraftMessages =
  (draftMessages: string, selectedSession: string) => async (dispatch: any) => {
    try {
      dispatch({
        type: SAVE_DRAFT_MESSAGE,
        payload: { draftMessages, selectedSession },
      });
    } catch (er: any) {
      errorHandling(er, dispatch, "Error_getting_client_info");
    }
  };

export const deleteDraftMessages =
  (sessionID: string) => async (dispatch: any) => {
    try {
      dispatch({
        type: DELETE_DRAFT_MESSAGE,
        payload: sessionID,
      });
    } catch (er: any) {
      errorHandling(er, dispatch, "Error_getting_client_info");
    }
  };

export const updateClientInfo = (data: any) => async (dispatch: any) => {
  try {
    const response: AxiosResponse = await chatService.postClientInfo(data);
    if (response.status === 200) {
      dispatch({
        type: SET_CLIENT_INFO,
        payload: data,
      });
      dispatch(triggerAlert(localize("success_update_client_info"), "success"));
    }
  } catch (er: any) {
    errorHandling(er, dispatch, "Error_update_client_info");
  }
};

export const transferSessionGroup =
  (data: FormData) => async (dispatch: any) => {
    try {
      data.append("reqID", uuid4());
      await chatService.transferSessionGrop(data);
      dispatch({
        type: TRANSFER_GROUP,
      });
      dispatch(triggerAlert(localize("transfer_group_success"), "success"));
    } catch (er: any) {
      errorHandling(er, dispatch, "transfer_group_fail");
    }
  };

export const openSessionFromArchive = (data: any) => async (dispatch: any) => {
  try {
    await chatService.sendMsgApi(data);
    //   let count = 1;
    let oldSession = data.get("sessionKey");
    messageOutStatus((data: any) => {
      // if (count === 1) {
      if (data?.status === "SUCCESS") {
        dispatch(triggerAlert(localize("open_session_success"), "success"));
        dispatch({
          type: MOVE_ITEM_ARCHIVE_TO_LIST_SESSION,
          payload: { data, oldSession },
        });
        socket.off("messageOutStatus");
        // count++;
        return;
      } else {
        dispatch(
          triggerAlert(
            localize(data?.failMsg || "create_message_failed"),
            "error"
          )
        );
      }
      // }
    });
  } catch (er: any) {
    errorHandling(er, dispatch, "open_session_failed");
  }
};

export const setDoneValue = () => (dispatch: any) => {
  dispatch({
    type: SET_DONE_SESSION,
  });
};

export const appliedFilters = (filters: currentFilters) => (dispatch: any) => {
  return dispatch({
    type: APPLY_FILTERS,
    payload: filters,
  });
};

export const sessionAgentFilter = (filter: number[]) => (dispatch: any) => {
  return dispatch({
    type: SESSION_AGENT_FILTER,
    payload: { filter },
  });
};

export const sessionGrouptFilter = (filter: string[]) => (dispatch: any) => {
  return dispatch({
    type: SESSION_GROUP_FILTER,
    payload: { filter },
  });
};

export const getVideoCallParticipants =
  (roomID: string) => async (dispatch: any) => {
    try {
      await getCallParticipants(roomID);
    } catch (er: any) {
      errorHandling(er, dispatch, "Error_get_call_participants");
    }
  };

export const onVideoCallParticipants = () => (dispatch: any) => {
  try {
    callParticipants((data: any) => {
      dispatch({
        type: VIDEO_CALL_PARTICIPANTS,
        payload: data,
      });
      return;
    });
  } catch (er: any) {
    errorHandling(er, dispatch, "Error_get_call_participants");
  }
};

export const receiveActivityAck = () => async (dispatch: any) => {
  try {
    activityAck((data: any) => {
      if (data?.status !== "FAILED") {
        dispatch({
          type: RECEIVE_NEW_REACTION,
          payload: data,
        });

        dispatch(triggerAlert(data?.status, "success"));
      } else if (data?.status === "FAILED") {
        dispatch(triggerAlert(data?.errMsg, "error"));
      }
    });
  } catch (er) {
    errorHandling(er, dispatch, "error_receiving_activity");
  }
};

export const reaction = (data: any) => async (dispatch: any) => {
  try {
    dispatch(loading());
    await chatService.sendActivity(data);
  } catch (er: any) {
    errorHandling(er, dispatch, "error_send_reaction");
  } finally {
    dispatch(stopLoading());
  }
};

export const getMsgActivities = (data: any) => async (dispatch: any) => {
  try {
    //loading reaction list
    dispatch({
      type: LOADING_REACTION_LIST,
    });

    await listMsgActivities(data);
  } catch (error: any) {
    errorHandling(error, dispatch, "error_getting_activities");
  }
};

export const receiveReactionsList = () => async (dispatch: any) => {
  try {
    msgActivities((data: any) => {
      dispatch({
        type: RECEIVE_REACTION_LIST,
        payload: data,
      });
    });
  } catch (error: any) {
    errorHandling(error, dispatch, "error_receiving_activities");
  }
};

export const updateMultiSelectedSessions = (data: any) => (dispatch: any) => {
  dispatch({
    type: UPDATE_MULTI_SELECTED_SESSIONS,
    payload: data,
  });
};

export const updateCurrentSessionType = (data: any) => (dispatch: any) => {
  dispatch({
    type: UPDATE_CURRENT_SESSION_TYPE,
    payload: data,
  });
};
