import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { authSelector } from "redux/auth/selector";
import CustomSwitch from "coreUI/constant/switch/CustomSwitch";
import { updateProfileStatus, updatingProfileStatus } from "redux/auth/actions";
import { socket } from "socketConfig/socketConnect";

interface Props {}

const OnlineIndicator = (props: Props) => {
  const profileStatus = useSelector(
    (state) => authSelector(state).profileStatus
  );

  const [userVisibility, setUserVisibility] =
    React.useState<boolean>(profileStatus);

  useEffect(() => {
    setUserVisibility((prev) => profileStatus);
  }, [profileStatus]);

  const dispatch = useDispatch();
  const handleChangeAgentStatus = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserVisibility(!userVisibility);
    dispatch(updatingProfileStatus(!e.target.checked));
  };

  useEffect(() => {
    dispatch(updateProfileStatus());
    return () => {
      socket.off("profileStatus");
    };
  });

  return (
    <CustomSwitch checked={userVisibility} onChange={handleChangeAgentStatus} />
  );
};

export default OnlineIndicator;
