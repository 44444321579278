import { SessionType } from "enums/sessions";
import { socket } from "./socketConnect";
import {
  CommunityGetHistory,
  CommunitySendMessage,
  ReadMessages,
} from "./socketEventsTypes";

// Make a post action
const socketEmit = (event: string, data: any) => socket.emit(event, data);
// Make a get action
const socketOn = (event: string, action: any) => socket.on(event, action);
// Make a get action
const socketOff = (event: string, action: any) => socket.off(event, action);

// Login Event
export const socketConnected = () => {
  return socketOn("connect", (socket: any) => socket.connected);
};
export const socketDisconnected = () => {
  return socketOn("disconnect", (socket: any) => socket.connected);
};

export const authenticationEvent = (jwt: string) => {
  return socketEmit("authentication", { jwt });
};

export const loginStatus = (action: (data: any) => void) => {
  return socketOn("loginStatus", (status: any) => {
    action(status);
  });
};

export const logoutEvent = () => {
  return socketEmit("logout", { anyKey: "" });
};

export const reloginEvent = (action: (data: any) => void) => {
  return socketOn("relogin", (status: any) => {
    action(status);
  });
};
export const logoutNotification = (action: (data: any) => void) => {
  return socketOn("logoutNotification", (status: any) => {
    action(status);
  });
};

// Chat messages event
export const messageLimit = (sessionId: object | "data") => {
  return socketEmit("messageLimit", { sessionId });
};

export const messageLimitStatus = (action: (data: any) => void) => {
  return socketOn("messageLimitStatus", (messageLimit: any) => {
    action(messageLimit);
  });
};

export const getSessionMessage = (
  sessionKey: string,
  limit: number = 0,
  offset: number = 0
) => {
  return socketEmit("getSessionMessages", { sessionKey, limit, offset });
};

export const loadMessage = async (action: (data: any) => void) => {
  socketOn("loadMessages", (messages: any) => {
    action(messages);
  });
};

export const sendMessage = (message: object) => {
  return socketEmit("sendMessage", message);
};

export const recieveMessage = (action: (data: any) => void) => {
  return socketOn("receiveMessage", (message: any) => {
    action(message);
  });
};

export const acknowledgeMessage = (session: object) => {
  return socketEmit("acknowledgeMessage", session);
};

export const getCallParticipants = (roomID: string) => {
  return socketEmit("getCallParticipants", { roomID });
};

export const callParticipants = (action: (data: any) => void) => {
  return socketOn("callParticipants", (participants: any) => {
    action(participants);
  });
};

export const activityAck = (action: (data: any) => void) => {
  return socketOn("activityAck", (activity: any) => {
    action(activity);
  });
}

export const listMsgActivities = (data: any) => {
  return socketEmit("listMsgActivities", data);
}

export const msgActivities = (action: (data: any) => void) => {
  return socketOn("msgActivities", (activities: any) => {
    action(activities);
  });
}

// Sessions event
export const loadAgentSessions = (
  sessionStatus: "active" | "inactive" | "close",
  offset = 0,
  selectedTab = "",
  filters: object = {}
) => {
  return socketEmit("loadAgentSessions", {
    sessionStatus,
    offset,
    selectedTab,
    filters,
  });
};

export const listAgentSessions = (action: (data: any) => void) => {
  return socketOn("listAgentSessions", (list: any) => {
    action(list);
  });
};

export const loadClientSessions = (
  sessionStatus: "all",
  offset = 0,
  filters: {
    username: string;
    dateSent: "DESC" | "ASC";
  } = {
    username: "",
    dateSent: "DESC",
  }
) => {
  return socketEmit("loadClientSessions", {
    sessionStatus,
    offset,
    filters,
  });
};

export const listClientSessions = (action: (data: any) => void) => {
  return socketOn("listClientSessions", (list: any) => {
    action(list);
  });
};

export const returnAgentSession = (session: {
  sessionKey: string;
  sessionStatus: SessionType.ESCALATED | SessionType.UNASSIGNED;
}) => {
  return socketEmit("returnSession", session);
};

export const closeAgentSession = (sessionKey: string, withSurvey: boolean) => {
  return socketEmit("closeSession", { sessionKey, withSurvey });
};

export const takeAgentSession = (sessionKey: string) => {
  return socketEmit("takeSession", { sessionKey });
};

export const escaleteAgentSession = (sessionKey: string) => {
  return socketEmit("escalateSession", { sessionKey });
};

// START OF session responsible on close and open chat view
export const joinStatus = (action: (data: any) => void) => {
  return socketOn("joinStatus", (status: any) => {
    action(status);
  });
};

export const leaveStatus = (action: (data: any) => void) => {
  return socketOn("leaveStatus", (status: any) => {
    action(status);
  });
};
// END OF session responsible on close and open chat view

// START OF actions responsible on updating number of active nd inactive and chats lists
export const sessionOpenSuccess = (action: (data: any) => void) => {
  return socketOn("sessionOpen", (status: any) => {
    action(status);
  });
};

export const sessionTaken = (action: (data: any) => void) => {
  return socketOn("sessionTaken", (status: any) => {
    action(status);
  });
};

export const sessionClosedSuccess = (action: (data: any) => void) => {
  return socketOn("sessionClosed", (status: any) => {
    action(status);
  });
};
// END of actions responsible on updating number of active nd inactive and chats lists
// Compose message
export const messageOut = (message: object) => {
  return socketEmit("messageOut", message);
};

export const messageOutStatus = (action: (data: any) => void) => {
  return socketOn("messageOutStatus", (msgStatus: any) => {
    action(msgStatus);
  });
};

export const messageStatus = (action: (data: any) => void) => {
  return socketOn("messageStatus", (msgStatus: any) => {
    action(msgStatus);
  });
};

// Profile Event
export const changeProfileStatus = (status: boolean) => {
  return socketEmit("changeProfileStatus", { status });
};

export const profileStatus = (action: (data: any) => void) => {
  return socketOn("profileStatus", (profileStatus: any) => {
    action(profileStatus);
  });
};

// Campaign Event
export const fetchCampaigns = (action: (data: any) => void) => {
  return socketOn("campaignList", (campaignList: any) => {
    action(campaignList);
  });
};

export const updateCampaigns = (action: (data: any) => void) => {
  return socketOn("campaignUpdate", (campaignData: any) => {
    action(campaignData);
  });
};

export const getCampaignData = (action: (data: any) => void) => {
  return socketOn("campaignMessages", (campaignData: any) => {
    action(campaignData);
  });
};

// Survey Event
export const fetchSurveys = (action: (data: any) => void) => {
  return socketOn("surveyList", (surveyList: any) => {
    action(surveyList);
  });
};

export const updateSurveys = (action: (data: any) => void) => {
  return socketOn("surveyUpdate", (surveyData: any) => {
    action(surveyData);
  });
};

export const getSurveyData = (action: (data: any) => void) => {
  return socketOn("surveyMessages", (surveyData: any) => {
    action(surveyData);
  });
};

// admin socket event
export const getEsclatedSession = (action: (data: any) => void) => {
  return socketOn("listEscalatedSessions", (escalated: any) => {
    action(escalated);
  });
};

export const getSuperSession = (action: (data: any) => void) => {
  return socketOn("listSuperSessions", (escalated: any) => {
    action(escalated);
  });
};

export const takeEsclatedSession = (sessionKey: string) => {
  return socketEmit("takeEscalatedSession", { sessionKey });
};

export const watchSession = (sessionKey: string) => {
  return socketEmit("watchSession", { sessionKey });
};

export const watchSessionSuccess = (action: (data: any) => void) => {
  return socketOn("watchSessionStatus", (status: any) => {
    action(status);
  });
};

export const returnToAgent = (sessionKey: string) => {
  return socketEmit("returnToAgent", { sessionKey });
};

export const loadSuperSession = (
  sessionStatus: "active" | "inactive" | "close",
  offset = 0,
  selectedTab = "",
  filters: object = {}
) => {
  return socketEmit("loadSuperSessions", {
    sessionStatus,
    offset,
    selectedTab,
    filters,
  });
};

export const loadEscalatedSession = (
  sessionStatus: "active" | "inactive",
  offset = 0,
  selectedTab = "",
  filters: object = {}
) => {
  return socketEmit("loadEscalatedSessions", {
    sessionStatus,
    offset,
    selectedTab,
    filters,
  });
};

export const requestCreateFlow = (
  Name = "",
  Description = "",
  Version = 0,
  Status: number = 1,
  Payload: string = "",
  UIPayload: string = "",
  Trigger: string,
  Options: any = null
) => {
  return socketEmit("createFlow", {
    Name,
    Description,
    Version,
    Status,
    Payload,
    UIPayload,
    Trigger,
    Options,
  });
};

export const returnCreateFlow = (action: (data: any) => void) => {
  return socketOn("createFlow", (item: any) => {
    action(item);
  });
};

export const requestListFlow = () => {
  return socketEmit("listFlow", {});
};

export const returnlistFlow = (action: (data: any) => void) => {
  return socketOn("listFlow", (item: any) => {
    console.log("list flow")
    action(item);
  });
};

export const requestGetFlow = (
  key: string,
  Version: number,
  Status: number
) => {
  return socketEmit("getFlow", { key, Version, Status });
};

export const returnGetFlow = (action: (data: any) => void) => {
  return socketOn("getFlow", (item: any) => {
    action(item);
  });
};

export const requestUpdateFlow = (
  Key: string,
  Name: string,
  Description: string,
  Version: number,
  Status: number,
  Payload: any,
  UIPayload: any,
  Trigger: string = "",
  ChannelIDs: string = "",
  Options: any = null
) => {
  return socketEmit("updateFlow", {
    Key,
    Name,
    Description,
    Version,
    Status,
    Payload,
    UIPayload,
    Trigger,
    ChannelIDs,
    Options,
  });
};
let updateFlowEventListener: Function | null = null;
export const returnUpdateFlow = (action: (data: any) => void) => {

  // Remove the previous event listener if it exists
  if (updateFlowEventListener) {
    socketOff("updateFlow", updateFlowEventListener);
  }
  // Create a new closure to capture the action
  const newEventListener = (item: any) => {
    action(item);
  };
  // Add the new event listener
  socketOn("updateFlow", newEventListener);
  // Update the reference to the new event listener
  updateFlowEventListener = newEventListener;

};

export const cancelInvocs = () => {
  return socketEmit("cancelInvocs", {
    key: "test123655444552222525874414",
    Version: 2,
    status: 1,
  });
};
export const cancelInvoc = () => {
  return socketEmit("cancelInvoc", {
    InvocationKey: "test123655444552222525874414",
    Version: 2,
    status: 1,
  });
};

export const getInvocs = () => {
  return socketEmit("getInvocs", {
    Key: "test123655444552222525874414",
    Version: 2,
    status: 1,
  });
};
// "ticket", "ticketAssigned", "ticketUnassigned", "ticketClosed", "ticketReopen", "ticketStatus", "ticketTransferred","ticketUpdated"
export const request = (action: (data: any) => void) => {
  return socketOn("ticket", (item: any) => {
    action(item);
  });
};

export const requestAssigned = (action: (data: any) => void) => {
  return socketOn("ticketAssigned", (item: any) => {
    action(item);
  });
};

export const requestUnassigned = (action: (data: any) => void) => {
  return socketOn("ticketUnassigned", (item: any) => {
    action(item);
  });
};

export const requestClosed = (action: (data: any) => void) => {
  return socketOn("ticketClosed", (item: any) => {
    action(item);
  });
};

export const requestReopen = (action: (data: any) => void) => {
  return socketOn("ticketReopen", (item: any) => {
    action(item);
  });
};

export const requestStatus = (action: (data: any) => void) => {
  return socketOn("ticketStatus", (item: any) => {
    action(item);
  });
};
export const requestTransfer = (action: (data: any) => void) => {
  return socketOn("ticketTransferred", (item: any) => {
    action(item);
  });
};

export const requestUpdated = (action: (data: any) => void) => {
  return socketOn("ticketUpdated", (item: any) => {
    action(item);
  });
};

export const communitySendMessage = (data: CommunitySendMessage) => {
  return socketEmit("communitySendMessage", data);
};

export const communitySendMessageConfirmation = (
  action: (data: any) => void
) => {
  return socketOn("communitySendMessage", (item: any) => {
    action(item);
  });
};

export const communityGetHistory = (data: CommunityGetHistory) => {
  return socketEmit("communityGetHistory", data);
};

export const communityHistory = (action: (data: any) => void) => {
  return socketOn("communityHistory", (item: any) => {
    action(item);
  });
};

export const communityMessage = (action: (data: any) => void) => {
  return socketOn("communityMessage", (item: any) => {
    action(item);
  });
};

export const communityReadMessages = (data: ReadMessages) => {
  return socketEmit("communityMsgsAck", data);
};

export const updateAgentCommunityVisibility = (isConnected: boolean) => {
  return socketEmit("communityAgentConnectStatus", { isConnected });
};

export const receiveAgentsCommunityVisibility = (
  action: (data: any) => void
) => {
  return socketOn("communityAgentConnectStatus", (item: any) => {
    action(item);
  });
};

export const updateAgentCommunityStatues = (agentStatues: string) => {
  return socketEmit("communityAgentStatus", { status: agentStatues });
};

export const receiveAgentsCommunityStatues = (action: (data: any) => void) => {
  return socketOn("communityAgentStatus", (item: any) => {
    action(item);
  });
};
