import { createSelector } from "reselect";
import { companyProps, shiftProps } from "./type";

export interface CompanySetting {
  company: companyProps | null,
  shifts: Record<string, shiftProps>,
  orginalShifts: shiftProps[]
}
interface RootState {
  companySetting: CompanySetting;
}


// This used to memorize the selector value
export const companySelector = createSelector(
  (state: RootState) => state.companySetting,
  (companySetting: CompanySetting) => {
    return {
      company: companySetting?.company,
      shifts: companySetting?.shifts
    };
  }
);
