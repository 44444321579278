import { keyBy } from "lodash";
import { CompanySetting } from "./selector";
import { UPDATE_COMPANY, GET_COMPANY, GET_SHIFT, CREATE_SHIFT, DELETE_SHIFT, UPDATE_SHIFT } from "./types";


const initialState: CompanySetting = {
  company: null,
  shifts: {},
  orginalShifts: []
};

export default function companySettingReducer(
  state = initialState,
  action: { type: any; payload: any }
) {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_COMPANY: {
      return {
        ...state,
        company: payload,
      };
    }
    case GET_COMPANY:
      return {
        ...state,
        company: payload,
      };
    case GET_SHIFT: {
      let res = keyBy(payload, "id")
      return {
        ...state,
        shifts: res,
        orginalShifts: payload
      };
    }
    case CREATE_SHIFT:
      return {
        ...state,
        shifts: { ...state.shifts, [payload?.id]: payload },
      };
    case DELETE_SHIFT: {
      let res = { ...state.shifts }
      delete res[payload]
      return {
        ...state,
        shifts: res
      };
    }
    case UPDATE_SHIFT:
      return {
        ...state,
        shifts: { ...state.shifts, [payload?.id]: payload },
      };

    default:
      return state;
  }
}
